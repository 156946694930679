// import {thunk} from "redux-thunk";
// import root from './redux/reducer';
// import { createStore, applyMiddleware,combineReducers } from "redux";
// import { composeWithDevTools } from "redux-devtools-extension";
// // import logger from 'redux-logger';

// const initialState = {};
// const middleware = [thunk];

// /* IN CASE OF MULTIPLE "REDUCER" */
// // const rootReducer = combineReducers({
// //     key1: root,
// //     key2: ANOTHER_REDUCER_HERE

// //   });

// // if (process.env.NODE_ENV === 'development') {
// //     middleware.push(logger);
// //   }

// const store = createStore(  root,  initialState,  composeWithDevTools(applyMiddleware(...middleware)));

// export default store;



// import thunk from "redux-thunk";
// import root from './redux/reducer';
// import { createStore, applyMiddleware,combineReducers } from "redux";
// import { composeWithDevTools } from "redux-devtools-extension";
// import logger from 'redux-logger';

// const initialState = {};
// const middleware = [thunk];

// if (process.env.NODE_ENV === 'development') {
//     middleware.push(logger);
//   }

// const store = createStore(  root,  initialState,  composeWithDevTools(applyMiddleware(...middleware)));

// export default store;

//persisit work
import { createStore, applyMiddleware } from 'redux';
import {thunk} from 'redux-thunk';
import root from './redux/reducer';

// Create the Redux store with middleware
const store = createStore(
  root, // Assuming `root` is your reducer
  applyMiddleware(thunk)
);

export default store;

import { USER_DETAIL } from "../action/type";
const INITIAL_STATE = {
    user_data: null,
}

export default (state = INITIAL_STATE, action) => {
    const { type, payload } = action;
    switch (type) {

        case USER_DETAIL:
            if (payload.status) {
                return {
                    ...state,
                    user_data: payload?.data
                };
            }
        default:
            return state;
    }
};
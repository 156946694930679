// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyDZSmxNuxfKaTOteuKtcXdBTBzCas-H-Fs",
    authDomain: "semcomm-55ecc.firebaseapp.com",
    projectId: "semcomm-55ecc",
    storageBucket: "semcomm-55ecc.appspot.com",
    messagingSenderId: "910846704150",
    appId: "1:910846704150:web:b3396252c6eaf5f92e5337",
    measurementId: "G-YTK1YF75SM"
};
let firbaseVapidKey = "BH15vFCXBpzEczUOY0d9llbvXUrEEwD64P-AAM-Rpb-8-tg6hLdQK6asvo60QTHc8pFlti23ge0jln259d5pmRg"
// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app)
export const firebaseAuth = getAuth(app)

export const getFcmToken = async () => {
    let currentToken = '';
    try {
        currentToken = await getToken(messaging, { vapidKey: firbaseVapidKey });
    } catch (error) {
        console.log('An error occurred while retrieving token ::::: ', error);
    }
    return currentToken;
};


// export const onMessageListener = (navRec) => {
//     console.log("navec------???????????", navRec);
//     onMessage(messaging, (payload) => {
//       console.log("Insideeee", payload);
//     //   toast.success("firebase notification");
//     //   callData = payload
//       if (navRec) {
//         navRec(payload);   
//       }
//     });
//   };   

let messaging = null
if (navigator.vendor !== 'Apple Computer, Inc.') {
    messaging = new getMessaging(app);
} else {
    console.log("Running on Safari")
}

// RESPONSOBLE FOR FIRING NOTIFICATION
export const onMessageListener = (navRec) => {
    new Promise((resolve, reject) => {
        onMessage(messaging, (payload) => {
            navRec(payload);
            resolve(payload);
        });
    });
}
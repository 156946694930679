import { Suspense, lazy, useEffect } from "react";
import "./App.css";
import { Helmet } from "react-helmet"
import semcommLogo from "./assets/semcomLogo.png"
import { Provider } from "react-redux";
import 'bootstrap/dist/css/bootstrap.min.css';
import store from "./store";
import { getFcmToken, onMessageListener } from "./firebase";
import { toast } from "react-toastify";


const AppRouter = lazy(() => import("./Routes"));
function App() {
  const fcmToken = async () => {
    let fcmToken = await getFcmToken()
    localStorage.setItem('fcmToken', fcmToken)
  }
  useEffect(() => {
    fcmToken()
  }, [])
  return (
    <Provider store={store}>
      <Suspense fallback={<div className="loading">
        <img src={semcommLogo} alt="logo" width={'15%'} />
      </div>}>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Semcomm</title>
          <link rel="canonical" href="http://3.20.134.98:3001/static/media/logo_colored.3f21a9b7104754a4c3e8.png" />
          <meta property="og:image" content="http://3.20.134.98:3001/static/media/logo_colored.3f21a9b7104754a4c3e8.png" />
        </Helmet>
        <AppRouter />
      </Suspense>
    </Provider>
  );
}

export default App;
